import type { IRangeBoundary } from '@/types/utility'

import { type DateInput, type Epoch, parseEpoch } from './DateUtils'

export type IDateRange = IRangeBoundary<Date>
export type IDateRangeInput = Partial<IRangeBoundary<DateInput>>

export class DateRange implements IDateRange {
  private readonly epoch: IRangeBoundary<Epoch>
  readonly start: Date | null
  readonly end: Date | null
  constructor(
    { start, end }: IDateRangeInput = {
      start: null,
      end: null,
    }
  ) {
    this.epoch = {
      start: parseEpoch(start),
      end: parseEpoch(end),
    }
    this.start = this.epoch.start === null ? null : new Date(this.epoch.start)
    this.end = this.epoch.end === null ? null : new Date(this.epoch.end)
  }
}
