import type { FC } from 'react'

import { Box } from '@mui/material'
import { Autoplay, Keyboard, Navigation, Pagination, A11y } from 'swiper/modules'
import 'swiper/css/a11y'
import 'swiper/css/autoplay'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'

import { CarouselItem, type CarouselItemJson } from '@/interfaces/carouselItem'
import Link from '@/components/ui/Link'

type HomeHeroCarouselProps = {
  data: CarouselItemJson[]
  className?: string
}

const HomeHeroCarousel: FC<HomeHeroCarouselProps> = ({ data, className = 'home-hero-carousel' }) => {
  const items = CarouselItem.fromJSON(data)

  return (
    <Swiper
      modules={[Autoplay, Keyboard, Navigation, Pagination, A11y]}
      className={className}
      loop={true}
      pagination={{ clickable: true }}
      autoplay={{
        disableOnInteraction: true,
        delay: 10000,
      }}
    >
      {items.map((item, index) => {
        const image = item.image
        return (
          item.visible() && (
            <SwiperSlide key={item.key}>
              <Link href={`${item.href}`} target="_blank" title={image.alt} noLinkStyle noRouter>
                <Box
                  position="relative"
                  width="100%"
                  sx={{ overflow: 'hidden', minHeight: { xs: 100, sm: 200, md: 360 } }}
                >
                  <Image
                    className="slide-image"
                    src={image.src.toString()}
                    alt={image.alt}
                    priority={index === 0}
                    loading={index === 0 ? 'eager' : 'lazy'}
                    fill
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Link>
            </SwiperSlide>
          )
        )
      })}
    </Swiper>
  )
}

export default HomeHeroCarousel
