import { isBlank } from '@/helpers/utils'

/**
 * The default timezone based on company head quarters location.
 */
export const TZ_HEADQUARTERS = 'America/Los_Angeles'

/**
 * Valid input types when calling `new Date()`
 */
export type DateInput = ConstructorParameters<DateConstructor>[0] | null

/**
 * Time value in milliseconds since midnight, January 1, 1970 UTC.
 */
export type Epoch = number

type SimpleDateInput = [value?: DateInput]
type ComplexDateInput = Parameters<DateConstructor['UTC']>

/**
 * Convert a given date-like value and return a time value
 * in milliseconds since midnight, January 1, 1970 UTC.
 *
 * @example
 * parseEpoch(2023, 12)
 * parseEpoch(2023, 12, 12)
 * parseEpoch('2023-12-12')
 * parseEpoch('2023-12-12T01:00:00Z')
 * parseEpoch(1706745600000)
 * parseEpoch(new Date('2023-12-12'))
 * parseEpoch() // => null
 * parseEpoch(null) // => null
 */
function parseEpoch(...args: SimpleDateInput): Epoch | null
function parseEpoch(...args: ComplexDateInput): Epoch | null
function parseEpoch(...args: SimpleDateInput | ComplexDateInput): Epoch | null {
  let result: Epoch | null = null
  const [value, monthIndex, ...rest] = args
  if (!isBlank(value)) {
    switch (typeof value) {
      case 'string':
        result = Date.parse(value.trim()) || null
        break
      case 'number':
        if (typeof monthIndex === 'number') {
          result = new Date(value, monthIndex, ...rest).getTime()
        } else {
          result = value
        }
        break
      default:
        if (value instanceof Date) {
          result = value.getTime()
        }
        break
    }
  }

  return result
}

export { parseEpoch }
