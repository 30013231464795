import { parseISO, startOfDay } from 'date-fns'
import { format } from 'date-fns-tz'
import { sortBy } from 'lodash'
import URI from 'urijs'

import { TZ_HEADQUARTERS } from '@/lib/comc/datetime/DateUtils'
import AvailabilityWindow from '@/lib/comc/datetime/AvailabilityWindow'
import { isBlank } from '@/helpers'

export interface CarouselItemJson {
  order?: number
  href: string
  availability?: {
    start?: string
    end?: string
  }
  image: {
    src: string
    alt?: string
  }
}

export class CarouselItem {
  key: string
  order: number
  href: URI
  availability: AvailabilityWindow
  image: {
    src: URI
    alt: string
  }

  constructor({ order, href, image, availability }: CarouselItemJson) {
    this.key = href
    this.order = order ?? 99
    this.href = new URI(href)
    this.image = {
      src: new URI(image.src),
      alt: image.alt || `Learn more at ${href}`,
    }
    this.availability = new AvailabilityWindow({
      start: this.normalizePublicationDate(availability?.start),
      end: this.normalizePublicationDate(availability?.end),
    })
  }

  get priority() {
    return this.order === 0
  }

  visible() {
    return this.availability.isAvailable()
  }

  static fromJSON(data: CarouselItemJson[] | string): CarouselItem[] {
    const inputs: CarouselItemJson[] = typeof data === 'string' ? JSON.parse(data) : data
    return sortBy(inputs, 'order').map((value, index) => new CarouselItem({ ...value, order: index }))
  }

  /**
   * @summary Convert a date or datetime string into an ISO 8601 string (YYYY-MM-DDTHH:mm:ssZ).
   *
   * Since we cannot know which runtime (server or client) will execute this code,
   * time is always set to midnight and the timezone is always set to {@linkcode TZ_HEADQUARTERS}
   *
   * @deprecated Remove once CMS integration is completed.
   * @since 1.2.0
   */
  private normalizePublicationDate(date: string | null = null): string | null {
    let normalized: string | null = null
    if (!isBlank(date)) {
      const dateStart = startOfDay(parseISO(date))
      normalized = format(dateStart, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: TZ_HEADQUARTERS })
    }
    return normalized
  }
}
