import { DateRange } from './DateRange'

/**
 * A type of {@link DateRange} that defines availability for content.
 *
 * Date range boundary includes `start` date and excludes `end` date
 * in the `isAvailable()` check.
 *
 * ATTN: No timezone manipulation is done by this class.
 */
class AvailabilityWindow extends DateRange {
  isAvailable() {
    const now = new Date()
    return (this.start === null || now >= this.start) && (this.end === null || now < this.end)
  }
}

export default AvailabilityWindow
